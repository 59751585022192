import { useState, useEffect } from 'react'
import { Grid, Container, Typography, Skeleton, Button } from '@mui/material'
import axios from '../../utils/axios'
import { useSettingsContext } from '../../components/settings';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

export default function Subscription() {

    const { themeStretch } = useSettingsContext();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const sessionId = params.get('session_id');
        const success = params.get('success');

        if (success && sessionId) {
            sendSuccessRequest(sessionId);
        }
    }, []);

    const sendSuccessRequest = async (sessionId: string) => {
        try {
            await axios.get('api/subscriptions/stripe-payment-success', { params: { session_id: sessionId } });
            navigate("/");
        } catch (e) {
            console.log(e)
        }
    }

    const pay = async () => {
        try {
            const { data: { content } } = await axios.get('api/subscriptions/stripe-payment-url');
            window.location.href = content.url;
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <Container maxWidth={themeStretch ? false : 'xl'}>
            <Grid container>
                <Grid container item direction="row" justifyContent="center" alignItems="flex-end" sx={{ marginTop: "100px" }}>
                    <Typography sx={{ fontSize: "50px" }}>
                        WKNDTRP
                    </Typography>
                </Grid>
                <Grid container item direction="row" justifyContent="center" alignItems="flex-start" sx={{ marginTop: "20px" }}>
                    <Typography align="center" sx={{ fontSize: "25px" }}>
                    Weekend Trip gives you access to the best hotels, restaurants,  <br />
                    bars, nightclubs, attractions, and shops in 50 top getaways.  <br /> <br />
                    Now, with the new "Travellers" feature, connect with fellow <br />
                    WKNDTRP users across these cities. <br /><br />
                    Subscribe to unlock this feature. cacel at any time
                    </Typography>
                </Grid>
                <Grid container item direction="row" justifyContent="center" alignItems="flex-start" sx={{ marginTop: "30px" }}>
                    <LoadingButton
                        loadingIndicator="LOADING…"
                        color="inherit"
                        size="medium"
                        variant="contained"
                        onClick={pay}
                        sx={{
                            // width: "100%",
                            bgcolor: '#A29060',
                            color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : '#FFFF'),
                            '&:hover': {
                                bgcolor: 'text.primary',
                                color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                            },
                        }}
                    >
                        Subscribe for $6.99 / 1 month
                    </LoadingButton>
                </Grid>
                {/* <Grid container item direction="row" justifyContent="center" alignItems="flex-start" sx={{ marginTop: "30px" }}>
                    <Typography align="center" sx={{ fontSize: "25px" }}>
                        Your trial has ended. Subscribe to keep exploring <br />
                        the app cancel anytime.
                    </Typography>
                </Grid> */}
                <Grid container item direction="row" justifyContent="center" alignItems="flex-start" sx={{ marginTop: "50px" }}>
                    <Typography align="center" sx={{ fontSize: "20px" }}>
                        Terms of Use & Privacy Policy
                    </Typography>
                </Grid>
                {/* <Button onClick={pay}>Pay</Button> */}
            </Grid>
        </Container>
    )
}