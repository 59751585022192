import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @mui
import { Alert, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Stack, Typography, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { LoadingButton } from '@mui/lab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment'
import dayjs from 'dayjs';

// layouts
import LoginLayout from '../../layouts/login';

import FormProvider, { RHFTextField } from '../../components/hook-form';
import axios from '../../utils/axios';


// ----------------------------------------------------------------------
type Loginrops = {
  id?: string;
  open: boolean;
  openPop: boolean;
  redirectPath?: string;
  // handleClickOpen: (id:string) => void;
  handleClose: () => void;
  handleClosePop: () => void;
  handleOpenVenueDetails?: (id: string | undefined) => void;
};

type FormValuesProps = {
  first_name: string
  last_name: string;
  password: string;
  afterSubmit?: string;
};

export default function Profile(Props: Loginrops) {
  const { open, handleClose } = Props;
  const { openPop, handleClosePop } = Props;
  const [loading, setLoading] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<any>("");
  const [lastName, setLastName] = useState<any>("");
  const [phone, setPhone] = useState<any>("");
  const [dob, setDob] = useState<any>("");
  const [gender, setGender] = useState<any>("");
  const [about, setAbout] = useState<any>("");


  useEffect(() => {

    const fetchUserProfile = async () => {
        try {
            setLoading(true)
            const { data } = await axios.get(`api/frontend/user/profile`)
            setFirstName(data.data.first_name_only)
            setLastName(data.data.last_name)
            setPhone(data.data.phone)
            setGender(data.data.gender)
            setAbout(data.data.about)
            if(data.data.dob){setDob(new Date(data.data.dob))}

            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error('ERROR WHILE FETCHING USER PROFILE')
        }
    }
    fetchUserProfile()
}, [])

  const updateUserData = async () => {  
    try {
      const response = await axios.post("api/frontend/user/profile",{
        first_name: firstName,
        last_name: lastName,
        dob: dob ? moment(dob).format('YYYY-MM-DD') : '',
        gender,
        about,
        phone
      });

      if(response.status===200){
        handleClose()
        handleClosePop()
      }else{
        setError('afterSubmit', { type: "server", message: "Something went wrong" });
      }
    } catch (error) {
      console.error(error);
      setError('afterSubmit', { type: "server", message: "Something went wrong" });
    }
  }


  const RegisterValidation = Yup.object().shape({
    // email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    // password: Yup.string().required('Password is required'),
  });



  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(RegisterValidation),
  });

  const {
    setError,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async () => {
    try {
      setLoading(true);
      updateUserData();
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError('afterSubmit', { type: "server", message: error?.errors?.message });
      setLoading(false);
    }
  };
  
  const onDateChange = (date:any) => {
    if(date && date.$d){
      setDob(date.$d);
    }
  };
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >

      <LoginLayout>

        <div style={{ fontFamily: 'Lato' }}>
          <Stack spacing={2} sx={{ mb: 2, position: 'relative' }} >
            <Typography variant="h5" style={{ fontWeight: 700 }}>Edit User Profile</Typography>
   
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>

                  {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
  
                  <TextField name="first_name" label="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)}  />
                  <TextField name="last_name" label="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)}  />
                  <TextField name="phone" label="Phone" type="number" value={phone} onChange={(e) => setPhone(e.target.value)}  />

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker label="Date of Birth" value={dayjs(dob)} format="YYYY-MM-DD" onChange={(e) => onDateChange(e)} />
                  </LocalizationProvider>

                   <FormControl>
                    <InputLabel id="demo-multiple-name-label">Gender</InputLabel>
                    <Select
                      id="demo-simple-select"
                      value={gender}
                      label="Gender"
                      input={<OutlinedInput label="Chip" />}
                      onChange={(e) => setGender(e.target.value)}
                    >
                    <MenuItem value='male'>Male</MenuItem>
                    <MenuItem value='female'>Female</MenuItem>
                  </Select>
                  </FormControl>
                  <RHFTextField name="about" label="About Me" value={about} onChange={(e) => setAbout(e.target.value)}  />

                  <LoadingButton
                    fullWidth
                    color="inherit"
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={loading}
                    sx={{
                      bgcolor: '#A29060',
                      color: (theme: any) => (theme.palette.mode === 'light' ? 'common.white' : '#FFFF'),
                      '&:hover': {
                        bgcolor: 'text.primary',
                        color: (theme: any) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                      },
                    }}
                  >
                    Save
                  </LoadingButton>
                </Stack>
              </FormProvider>
          </Stack>
        </div>
      </LoginLayout>
    </Dialog>
  );
}
